import React, { useState, useEffect, useCallback } from "react";
import Dashboard from "./components/Dashboard";
import { AgentContextProvider } from './components/AgentContextProvider';
import { ContactContextProvider } from './components/ContactContextProvider';
import Login from "./components/Login/Login";
import { clientId, redirectUri, signInUrl } from './config.js';
import { auth } from './services/models/auth.js';
import { jwtDecode } from 'jwt-decode';

const App = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [idToken, setIdToken] = useState('');
  const [groups, setGroups] = useState([]);
  
  const search = window.location.search;
  const codeParam = new URLSearchParams(search);
  const code = codeParam.get('code');
  window.history.replaceState({}, document.title, "/");

  const login = useCallback(() => {
    if (!code) {
      window.location.href = signInUrl;
    } else {
      auth.authpost({ grant_type: 'authorization_code', client_id: clientId, code: code, redirect_uri: redirectUri })
        .then((res) => {
          const idToken = res.data.id_token;
          setIdToken(idToken);
          setGroups(jwtDecode(idToken)['cognito:groups']);
        })
        .catch((error) => {
          console.error('Error during authentication:', error);
        });
    }
  }, [code]);

  useEffect(() => {
    login();
  }, [login]);

  useEffect(() => {
    if (idToken) {
      setIsSignedIn(true);
      console.log(">>>  App.jsx idToken = ", idToken);
    }
  }, [idToken]);

  return (
    <div className='mainApp'>
      <AgentContextProvider>
        <ContactContextProvider>
          {isSignedIn ? <Dashboard idToken={idToken} groups={groups} /> : <Login />}
        </ContactContextProvider>
      </AgentContextProvider>
    </div>
  );
};

export default App;