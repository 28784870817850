import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { AgentContext } from './AgentContextProvider';
import useObserver from "./useObserver/useObserver";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "0.2rem",
    paddingLeft: "0.4rem",
    backgroundColor: "#4CC6FF",
    fontWeight: "bold",
    borderRadius: "5px",
  },
  body: {
    padding: "0.5rem",
  },
});

const widgetNames = {
  a: 'CCP',
};

const Widget = ({ component: Item, id, idToken, groups }) => {
  const { isLoggedIn, widgets } = useContext(AgentContext);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const ref = useRef(null);

  const sizeCallback = (ref) => {
    setHeight(ref[0].contentRect.height);
    setWidth(ref[0].contentRect.width);
  };

  useObserver({ callback: sizeCallback, element: ref });

  useEffect(() => {
    if (!isLoggedIn) return;
    widgets.forEach(widget => {
      widgetNames[widget.i] = widget.displayname;
    });
  }, [isLoggedIn, widgets]);

  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={12} ref={ref}>
      {widgetNames[id] && (
        <div className={classes.header}>
          <Typography variant="h6">
            {widgetNames[id]}
          </Typography>
        </div>
      )}
      <div className={classes.body}>
        <Item idToken={idToken} height={height} width={width} groups={groups} />
      </div>
    </Paper>
  );
};

export default Widget;