import React, { memo, useEffect, useRef } from "react";
import { updateSapId, refreshSms, resetFields } from "../updateSapId.js";
import subscribeToContactEvents from '../contactEvents.js';
import './sms.css';

const Sms = () => {
  const sapIdRef = useRef(null);
  const contactFnameRef = useRef(null);
  const contactLnameRef = useRef(null);
  const contactEmailRef = useRef(null);
  const editBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  useEffect(() => {
    window.connect.contact(subscribeToContactEvents);
  }, []);

  const loadTab = (evt, chatNum) => {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    const tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
      tablinks[i].disabled = true;
    }

    document.getElementById(chatNum).style.display = "block";
    evt.currentTarget.className += " active";
    document.getElementById(`${chatNum}button`).disabled = false;
  };

  const disableFields = () => {
    sapIdRef.current.disabled = true;
    contactFnameRef.current.disabled = true;
    contactLnameRef.current.disabled = true;
    contactEmailRef.current.disabled = true;
    editBtnRef.current.innerHTML = "Edit";
    cancelBtnRef.current.disabled = true;
  };

  const editFields = () => {
    if (editBtnRef.current.innerHTML === "Save") {
      if (!updateSapId()) {
        editBtnRef.current.innerHTML = "Edit";
      }
      disableFields();
      return;
    }

    const email = contactEmailRef.current.value;
    const sapId = sapIdRef.current.value;
    if (!email || !sapId) {
      console.log("No active contact. Cannot edit");
      return;
    }

    sapIdRef.current.disabled = false;
    contactFnameRef.current.disabled = false;
    contactLnameRef.current.disabled = false;
    contactEmailRef.current.disabled = false;
    editBtnRef.current.innerHTML = "Save";
    cancelBtnRef.current.disabled = false;
  };

  return (
    <div className="item contact-attributes">
      <div className="contact-attributes-container">
        <div className="table-header">Contact Attributes</div>
        <div className="table-content">
          <table id="t01" style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "15%" }} id="l_fname" className="not_draggable">First Name</td>
                <td style={{ width: "35%" }}>
                  <input id="contact_fname" type="text" style={{ width: "90%" }} className="not_draggable" ref={contactFnameRef} disabled />
                </td>
                <td style={{ width: "15%" }} className="not_draggable">Subject</td>
                <td style={{ width: "35%" }}>
                  <input id="contact_subject" type="text" style={{ width: "90%" }} className="not_draggable" disabled />
                </td>
              </tr>
              <tr>
                <td style={{ width: "15%" }} id="l_lname" className="not_draggable">Last Name</td>
                <td style={{ width: "35%" }}>
                  <input id="contact_lname" type="text" style={{ width: "90%" }} className="not_draggable" ref={contactLnameRef} disabled />
                </td>
                <td style={{ width: "15%" }} className="not_draggable">Product</td>
                <td style={{ width: "35%" }}>
                  <input id="contact_product" type="text" style={{ width: "90%" }} className="not_draggable" disabled />
                </td>
              </tr>
              <tr>
                <td style={{ width: "15%" }} id="l_email" className="not_draggable">Email</td>
                <td style={{ width: "35%" }}>
                  <input id="contact_email" type="text" style={{ width: "90%" }} className="not_draggable" ref={contactEmailRef} disabled />
                </td>
                <td style={{ width: "15%" }}>Category</td>
                <td style={{ width: "35%" }}>
                  <input id="category" type="text" style={{ width: "90%" }} className="not_draggable" disabled />
                </td>
              </tr>
              <tr>
                <td style={{ width: "15%" }} className="not_draggable">Queue</td>
                <td style={{ width: "35%" }}>
                  <input id="contact_queue" type="text" style={{ width: "90%" }} className="not_draggable" disabled />
                </td>
                <td style={{ width: "15%" }} className="not_draggable">Application ID</td>
                <td style={{ width: "35%" }}>
                  <input id="app_id" type="text" style={{ width: "90%" }} className="not_draggable" disabled />
                </td>
              </tr>
              <tr>
                <td>SAP ID</td>
                <td>
                  <input id="sap_id" type="text" style={{ width: "50%" }} className="not_draggable" ref={sapIdRef} disabled />
                  <button id="editbtn" onClick={editFields} ref={editBtnRef}>Edit</button>
                  <button id="cancelbtn" onClick={resetFields} ref={cancelBtnRef} disabled>Cancel</button>
                </td>
                <td>
                  <button id="sap_update" onClick={refreshSms}>Refresh SMS</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="tab">
            <button className="tablinks" id="chatonebutton" onClick={(e) => loadTab(e, 'chatone')} hidden>Chat One</button>
            <button className="tablinks" id="chattwobutton" onClick={(e) => loadTab(e, 'chattwo')} hidden>Chat Two</button>
            <button className="tablinks" id="chatthreebutton" onClick={(e) => loadTab(e, 'chatthree')} hidden>Chat Three</button>
          </div>
          <div id="chatone" className="tabcontent" hidden>
            <iframe id="smsUrl1" src="about:blank" title="Chat One" style={{ width: "850px", height: "370px" }} frameBorder="0"></iframe>
          </div>
          <div id="chattwo" className="tabcontent" hidden>
            <iframe id="smsUrl2" src="about:blank" title="Chat Two" style={{ width: "850px", height: "370px" }} frameBorder="0"></iframe>
          </div>
          <div id="chatthree" className="tabcontent" hidden>
            <iframe id="smsUrl3" src="about:blank" title="Chat Three" style={{ width: "850px", height: "370px" }} frameBorder="0"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Sms);