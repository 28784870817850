export function handleResponse(response) {
    if (response.results) {
      return response.results;
    }
  
    if (response.data) {
      return response.data;
    }
  
    return response;
  }
  
  export function handleError(error) {
    if (error.response && error.response.data) {
      console.error('Error:', error.response.data);
      return error.response.data;
    }
  
    console.error('Error:', error);
    return error;
  }