import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import './css/index.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

serviceWorker.unregister();