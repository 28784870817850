import React, { useState, useCallback } from 'react';

export const ContactContext = React.createContext({
  onContact: false,
  status: '',
  setOnContact: () => {},
  attributes: {},
  contactids: [],
  primarycontactid: '',
  isCall: false
});

export const ContactContextProvider = (props) => {
  const [state, setState] = useState({
    onContact: false,
    status: '',
    setOnContact: () => {},
    attributes: {},
    contactids: [],
    primarycontactid: '',
    isCall: false
  });

  const setOnContact = useCallback((onContact, status, attributes, contactids, primarycontactid, isCall) => {
    setState((prevState) => ({
      ...prevState,
      onContact,
      status,
      attributes,
      contactids,
      primarycontactid,
      isCall
    }));
  }, []);

  return (
    <ContactContext.Provider value={{ ...state, setOnContact }}>
      {props.children}
    </ContactContext.Provider>
  );
};