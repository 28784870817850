import React, { memo, useCallback, useContext, useState } from "react";
import "amazon-connect-streams";
import Modal from '@mui/material/Modal';
import IconButton from "@mui/material/IconButton";
import AgentTransfer from "./AgentTransfer";
import QueueTransfer from "../QueueTransfer/QueueTransfer";
import TransferCallIcon from '@mui/icons-material/PhoneForwardedSharp';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@mui/styles";
import { AgentContext } from '../AgentContextProvider';
import { ContactContext } from '../ContactContextProvider';
import { useConnected, useCallCompleted, useDestroy } from '../../hooks.js';

const modalStyle = {
  top: '10%',
  left: '25%',
  border: '1px solid #ccc',
  background: '#fff',
  borderRadius: '4px',
  outline: 'none',
  height: '650px',
  width: '1000px',
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "650px",
    display: "flex",
    flexDirection: "column"
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "0.2rem",
    paddingLeft: "0.4rem",
    backgroundColor: "#4CC6FF",
    fontWeight: "bold",
    borderRadius: "5px"
  },
  body: {
    flex: 1,
    overflow: 'auto'
  },
});

const AgentTransferModal = (props) => {
  const { transferClient, agentRows, queueRows } = props;
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("agent");
  const state = useContext(AgentContext);
  const contactState = useContext(ContactContext);
  const classes = useStyles();
  const idToken = state.idToken;

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleTabChange = useCallback((event, newValue) => setSelectedTab(newValue), []);

  const setPrimaryID = useCallback((attrobj) => {
    let newObj = { ...contactState, ...attrobj };
    delete newObj['setOnContact'];
    let listArgs = Object.values(newObj).map(val => val);
    contactState.setOnContact(...listArgs);
  }, [contactState]);

  const onConnected = useCallback((c) => {
    try {
      const isCall = c.isSoftphoneCall();
      let currentContact = c.contactId;
      setPrimaryID({ primarycontactid: currentContact, onContact: true, isCall });
    } catch (e) {
      console.error("Error in onConnected: ", e);
    }
  }, [setPrimaryID]);
  useConnected(onConnected);

  const onCallCompleted = useCallback((c) => {
    let currentContact = c.contactId;
    setPrimaryID({ primarycontactid: currentContact, onContact: true, isCall: false });
  }, [setPrimaryID]);
  useCallCompleted(onCallCompleted);

  const onDestroy = useCallback(() => {
    setPrimaryID({ primarycontactid: '', onContact: false });
  }, [setPrimaryID]);
  useDestroy(onDestroy);

  return (
    <div>
      <IconButton aria-label="transfer call" onClick={handleOpen} disabled={!contactState.isCall}>
        <TransferCallIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={modalStyle}
      >
        <Paper className={classes.root} elevation={12}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="transfer tabs">
            <Tab label="Transfer to Agent" value="agent" />
            <Tab label="Transfer to Queue" value="queue" />
          </Tabs>
          {selectedTab === "agent" && (
            <div className={classes.body}>
              <AgentTransfer idToken={idToken} transferClient={transferClient} agentRows={agentRows} />
            </div>
          )}
          {selectedTab === "queue" && (
            <div className={classes.body}>
              <QueueTransfer idToken={idToken} transferClient={transferClient} queueRows={queueRows} />
            </div>
          )}
        </Paper>
      </Modal>
    </div>
  );
};

export default memo(AgentTransferModal);