import React, { memo, useCallback, useContext, useState, useEffect, useRef } from 'react';
import './calldisposition.css';
import { AgentContext } from '../AgentContextProvider';
import { ContactContext } from '../ContactContextProvider';
import { useConnected, useDestroy, useCallCompleted, contact } from '../../hooks.js';
import { instanceId } from '../../config.js';
import { apiDisposition } from '../../services/models/disposition.js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import DispositionModal from './DispositionModal';

const CallDisposition = ({ width, height }) => {
  const { isLoggedIn, routingProfile, idToken } = useContext(AgentContext);
  const { onContact, primarycontactid } = useContext(ContactContext);

  const [customerDisposition, setCustomerDisposition] = useState(null);
  const [dispositions, setDispositions] = useState([]);
  const [selectValues, setSelectValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const dispositionSetRef = useRef(customerDisposition);
  const selectValuesRef = useRef(selectValues);
  const defaultValuesRef = useRef(defaultValues);

  useEffect(() => {
    dispositionSetRef.current = customerDisposition;
  }, [customerDisposition]);

  useEffect(() => {
    if (!isLoggedIn) return;

    selectValuesRef.current = selectValues;
    const vals = Object.values(selectValues);
    const render = vals.includes('(Choose One)');

    setSubmitDisabled(render || vals.length === 0);
  }, [selectValues, isLoggedIn]);

  useEffect(() => {
    defaultValuesRef.current = defaultValues;
  }, [defaultValues]);

  const onConnected = useCallback(() => {
    setCustomerDisposition(false);
  }, []);

  useConnected(onConnected);

  const onCallCompleted = useCallback((c) => {
    if (!dispositionSetRef.current) {
      setOpenModal(true);
    } else {
      c.clear();
    }
  }, []);

  useCallCompleted(onCallCompleted);

  const onDestroy = useCallback(() => {
    const form = document.getElementById('Disposition Form');
    setOpenModal(false);
    form.reset();
    setSelectValues(defaultValuesRef.current);
    setSubmitDisabled(true);
  }, []);

  useDestroy(onDestroy);

  useEffect(() => {
    if (!isLoggedIn) return;

    const getDispositions = async () => {
      try {
        const res = await apiDisposition.get('', { routingProfile });
        const items = res;
        const valObject = Object.fromEntries(
          Object.entries(items).map(([key, value]) => [key, value.map(Object.values)[0][0]])
        );
        setDefaultValues(valObject);
        setSelectValues(valObject);
        setDispositions(items);
      } catch (e) {
        console.error(e);
      }
    };

    getDispositions();
  }, [isLoggedIn, routingProfile]);

  const handleChange = (e, key) => {
    setSelectValues({ ...selectValues, [key]: e.target.value });
  };

  const updateDisposition = async (e) => {
    e.preventDefault();

    if (!onContact) {
      setSelectValues(defaultValues);
      setSubmitDisabled(true);
      console.error('No contact found.');
      return;
    }

    try {
      const updatedDisposition = selectValues;
      await apiDisposition.put(
        {
          routingProfile,
          instanceId,
          contactId: primarycontactid,
          attributes: updatedDisposition,
        },
        idToken
      );
      setCustomerDisposition(true);

      if (openModal) {
        setOpenModal(false);
        contact.clear();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <Box>
        <form onSubmit={updateDisposition} id="Disposition Form">
          {dispositions.length > 0 ? (
            <>
              <TextField
                style={{ marginRight: '5px', marginBottom: '10px' }}
                disabled
                InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 18 } }}
                className="not_draggable"
                value={primarycontactid}
                id="contactid"
                label="Current Contact"
                fullWidth
              />

              {Object.entries(dispositions).map(([key, value]) => (
                <div key={`${key}-disposition-dev`}>
                  <TextField
                    style={{ paddingBottom: '10px' }}
                    id={key}
                    key={`${key}-disposition-select`}
                    InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 18 } }}
                    select
                    required
                    label={key}
                    className="not_draggable"
                    onChange={(e) => handleChange(e, key)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={selectValues[key]}
                  >
                    {value.map((val) =>
                      Object.entries(val).map(([k, v]) => (
                        <MenuItem id={k} key={`${key}-disposition-value`} value={v}>
                          {v}
                        </MenuItem>
                      ))
                    )}
                  </TextField>
                </div>
              ))}

              <Button type="submit" variant="contained" disabled={submitDisabled}>
                Submit
              </Button>

              {openModal && (
                <DispositionModal
                  InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 18 } }}
                  modalWidth={width}
                  modalHeight={height}
                  updateDisposition={updateDisposition}
                  dispositions={dispositions}
                  selectValues={selectValues}
                  handleChange={handleChange}
                  openModal={openModal}
                  submitDisabled={submitDisabled}
                />
              )}
            </>
          ) : (
            <div>Loading...</div>
          )}
        </form>
      </Box>
    </div>
  );
};

export default memo(CallDisposition);