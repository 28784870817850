import session from './session.js';
import ui from './ui.js';
import "amazon-connect-streams";
import "amazon-connect-chatjs";
import { smsDomain } from '../config.js'
import { apiGetAuthId } from '../services/models/getauthid.js';

var smsTabs = [{ 'contact_id': 'null', 'tabId': 'chatonebutton', 'divId': 'chatone', 'iframeId': 'smsUrl1' },
{ 'contact_id': 'null', 'tabId': 'chattwobutton', 'divId': 'chattwo', 'iframeId': 'smsUrl2' },
{ 'contact_id': 'null', 'tabId': 'chatthreebutton', 'divId': 'chatthree', 'iframeId': 'smsUrl3' }]

//The onViewContact handler is being called multiple times from CCP
//To workaround the issue, save the context for recent tab switch
var lastContext = { 'contactId': 'null', 'displayAttr': true }

function handleContact(contact) {
    let connect = window.connect;
    session.contact = contact;
    const currentContact = session.contact;
    // For Voice channel
    if (contact.getType() === "softphone") {
        currentContact.activeConnection = contact.getActiveInitialConnection();
        currentContact.contactId = currentContact.activeConnection['contactId'];
        currentContact.connectionId = currentContact.activeConnection['connectionId'];
        currentContact.connection = new connect.Connection(currentContact.contactId, currentContact.connectionId);
        currentContact.customerNo = currentContact.connection.getEndpoint().phoneNumber;
    }
    currentContact.snapshot = contact.toSnapshot();
    currentContact.inboundConnection = null;
    currentContact.outboundConnection = null;

    for (var i = 0; i < currentContact.snapshot.contactData.connections.length; i++) {
        if (currentContact.snapshot.contactData.connections[i].type === 'inbound') {
            currentContact.inboundConnection = currentContact.snapshot.contactData.connections[i];
        }
        if (currentContact.snapshot.contactData.connections[i].type === 'outbound') {
            currentContact.outboundConnection = currentContact.snapshot.contactData.connections[i];
        }
    }

    //Set handlers for contact events
    contact.onIncoming(handleContactIncoming);
    contact.onAccepted(handleContactAccepted);
    contact.onConnecting(handleContactConnecting);
    contact.onConnected(handleContactConnected);
    contact.onEnded(handleContactEnded);
    contact.onACW(handleContactAcw);
    contact.onDestroy(handleContactDestroy);
    contact.onMissed(handleContactMissed);

    //For multiple chat - when agent switches between different contacts
    connect.core.onViewContact(handleOnViewContact);

    function handleContactIncoming(contact) {
        console.log(contact, 'handleContactIncoming', 'Contact is incoming');
    }

    function handleContactAccepted(contact) {
        console.log(contact, 'handleContactAccepted', 'Contact accepted by agent');
        let connectAgent = session.agent;
        connectAgent.getEndpoints(connectAgent.getAllQueueARNs(), {
            success: function (data) {},
            failure: function () {}
        });
    }

    function handleContactConnecting(contact) {
        console.log(contact, 'handleContactConnecting', 'Contact connecting to agent');
    }

    function handleContactConnected(contact) {
        try {
            console.log(contact, 'handleContactConnected', 'Contact connected to agent.');

            setTab(contact);

            let attr = currentContact.getAttributes();

            console.log("ATTRIBUTES", attr);
        } catch (e) {
            console.log(e);
        }
    }

    function handleContactEnded(contact) {
        console.log(contact, 'handleContactEnded', 'Contact has ended successfully');
        session.state.callEnded = true;
        postSmsMessage("chatEnded", contact);
    }

    function handleContactAcw(contact) {
        console.log(contact, 'handleContactAcw', 'Contact has moved to ACW');
    }

    function handleContactMissed(contact) {
        console.log(contact, 'handleContactMissed', 'Contact has been missed');
    }

    function handleContactDestroy(contact) {
        try {
            console.log(contact, 'handleContactDestroy', 'Contact has ended successfully');

            //Clear the active chat being destroyed
            let contactList = session.agent.getContacts("chat");
            console.log(contactList);

            if (contactList.length === 0) {
                clearChatFields();
            }

            //Remove tab
            removeTab(contact);
        } catch (e) {
            console.log(e);
        }
    }

    //Invoked when Agent switches between chat tabs
    function handleOnViewContact(currentContactId) {
        try {
            console.log(currentContactId, 'handleOnViewContact', ' Chat context switched');
            console.info(lastContext);

            //Fix to run this handler once when tab is switched
            console.log('Last Contact: ' + lastContext.contactId);
            console.log('currentContact: ' + currentContact.contactId);
            if (lastContext.contactId === 'null' || lastContext.contactId !== currentContactId.contactId) {
                lastContext.contactId = currentContactId.contactId;
                lastContext.displayAttr = false;

                // When new tab is switched - always reset the fields
                disableAttrFields();

                //Get active contacts for the agent
                var contactList = session.agent.getContacts("chat");

                //Match the contact ID
                for (const contact of contactList) {
                    if (String(currentContactId.contactId) === contact.contactId) {
                        //Update session var with switched contact
                        session.contact = contact;
                        let attr = contact.getAttributes();
                        console.log(attr);
                        ui.setText("contact_fname", attr['FirstName'].value);
                        ui.setText("contact_lname", attr['LastName'].value);
                        ui.setText("contact_subject", attr['Subject'].value);
                        ui.setText("contact_email", attr['Email'].value);
                        ui.setText("contact_product", attr['Product'].value);
                        ui.setText("contact_queue", contact.getQueue().name);
                        ui.setText("category", attr['Category'].value);
                        if (attr['ServiceNumber'].value) {
                            ui.setText("app_id", attr['ServiceNumber'].value);
                        } else {
                            ui.setText("app_id", "");
                        }

                        if (attr['sapId'].value !== undefined) {
                            ui.setText("sap_id", attr['sapId'].value);
                        } else {
                            ui.setText("sap_id", "");
                        }

                        //Display SMS TAB
                        displayTab(contact);
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    session.state = {
        incoming: true,
        accepted: false,
        connected: false,
        callEnded: false,
        ticketSubmitted: false
    };
}

export default handleContact;

function smsAuth(iframe) {

    let smsSapUrl = smsDomain + "/external/rightnow.jsp?rightnow_auth_id="

    apiGetAuthId.get('', {})
        .then((res) => {
            console.log(res)
            iframe.src = smsSapUrl + res.authid
        });
}

async function setTab(contact) {
    // Get attributes
    let attr = contact.getAttributes();

    // Find Index of first 'null' text
    let index = smsTabs.findIndex(function (element) {
        return element.contact_id === 'null';
    })

    //Set contact id to first index with 'null' -  this associates the tab to this specific customer
    smsTabs[index].contact_id = contact.contactId

    //Finds the corresponding contact id set above
    let tabVal = smsTabs.find(function (element) {
        return element.contact_id === contact.contactId
    })

    //Wait for Iframe to load
    const iframe = document.getElementById(tabVal.iframeId);
    smsAuth(iframe)

    console.log(document.getElementById('chatone'))
    iframe.onload = () => {
        console.log('iframe is completely loaded');
        postSmsMessage("chatConnected", contact);
    }
    //Enable the button for click event
    if (document.getElementById(tabVal.tabId).disabled !== false) {
        document.getElementById(tabVal.tabId).disabled = false
    }

    // If the tab is hidden, display it.
    if (document.getElementById(tabVal.tabId).hidden !== false) {
        document.getElementById(tabVal.tabId).hidden = false
    }

    console.log(document.getElementById(tabVal.tabId))
    // Add Contact name as Tab header to make it easier on the associate
    document.getElementById(tabVal.tabId).innerHTML = attr['FirstName'].value;
    document.getElementById(tabVal.tabId).click();
}

function displayTab(contact) {
    //Display SMS tab and return the element ID
    let index = smsTabs.find(function (element) {

        return element.contact_id === contact.contactId;
    })

    if (index !== undefined) {
        document.getElementById(index.tabId).disabled = false;
        document.getElementById(index.tabId).click();
    }

    return true;
    //return document.getElementById(index.tabId);
}

function removeTab(contact) {
    //Removes SMS TAB when contact is destroyed
    let index = smsTabs.find(function (element) {
        return element.contact_id === contact.contactId
    })
    if (index === undefined) {
        //Scenario when user refreshes the browser while on since chat -> EndContact
        return false
    }

    //Refresh the tab
    document.getElementById(index.iframeId).src = document.getElementById(index.iframeId).src;
    document.getElementById(index.tabId).hidden = true;
    document.getElementById(index.divId).style.display = 'none';
    index.contact_id = 'null';
}

export function postSmsMessage(eventName, smsContact) {
    // Use PostMessage to pass data to corresponding SMS iFrame tab
    //Get Current contact
    try {
        let attr = smsContact.getAttributes();
        let queueId = ((smsContact.getQueue().queueARN).split("/"))[3];

        let contactMessage = {
            "event": eventName,
            "contactId": smsContact.getContactId(),
            "firstName": attr['FirstName'].value,
            "lastName": attr['LastName'].value,
            "email": attr['Email'].value,
            "product": attr['Product'].value,
            "category": attr['Category'].value,
            "serviceNumber": attr['ServiceNumber'].value,
            "subject": attr['Subject'].value,
            "queueName": smsContact.getQueue().name,
            "queueId": queueId,
            "agent": session.agent.getConfiguration().username,
            "sap_id": attr['sapId'].value
        };

        let message = JSON.stringify(contactMessage);

        //Post to corresponding SMS element

        let index = smsTabs.find(function (element) {
            return element.contact_id === smsContact.contactId
        })
        if (index === undefined) {
            console.info('Index not defined')
            //Scenario: Refresh browser while Endchat
            //Since no active SMS context available
            return
        }

        let iframe = document.getElementById(index.iframeId);

        if (iframe) {
            let iframeWin = iframe.contentWindow;
            console.log("PostMessage:" + eventName + message);
            console.log("iframe el", iframe)
            iframeWin.postMessage(message, smsDomain);
        }
        else {
            console.log("Error in posting.Iframe doesnt exist");
            return false;
        }
        return true;
    } catch (e) {
        console.log(e)
    }
}

function clearChatFields() {
    //Clear the form data
    try {
        ui.clearValue("contact_fname");
        ui.clearValue("contact_lname");
        ui.clearValue("contact_subject");
        ui.clearValue("contact_email");
        ui.clearValue("contact_product");
        ui.clearValue("contact_queue");
        ui.clearValue("sap_id");
        ui.clearValue("app_id");
        ui.clearValue("category");
    } catch (e) { console.log(e) }

    return;
}

function disableAttrFields() {
    //Disable the fields when non-editable
    document.getElementById("sap_id").disabled = true;
    document.getElementById("contact_fname").disabled = true;
    document.getElementById("contact_lname").disabled = true;
    document.getElementById("contact_email").disabled = true;
    document.getElementById("editbtn").innerHTML = "Edit";


}

export function getSmsTabs() {
    //return smsTabs
    return smsTabs;
}