import appSettings from './appSettings.js';

const getElementById = (id) => {
  if (!document) return null;
  return document.getElementById(id);
};

const show = (id, showAs = 'block') => {
  const el = getElementById(id);
  console.log(`making ${id} visible`, el);
  if (el) el.style.display = showAs;
};

const hide = (id) => {
  const el = getElementById(id);
  if (el) el.style.display = 'none';
};

const enable = (id, enabled = true) => {
  const el = getElementById(id);
  if (el) el.disabled = !enabled;
};

const config = {
  attributes: true,
  childList: true,
  subtree: true
};

const onMutation = (mutationsList) => {
  for (const mutation of mutationsList) {
    console.log('mutation:', mutation);
    if (mutation.type === 'childList') {
      console.log('A child node has been added or removed.');
    } else if (mutation.type === 'attributes') {
      console.log(`The ${mutation.attributeName} attribute was modified.`);
    }
  }
};

const observer = new MutationObserver(onMutation);

const observe = (containerId) => {
  observer.observe(getElementById(containerId), config);
};

const getText = (elementId) => {
  const el = getElementById(elementId);
  return el ? el.value || el.innerText : '';
};

const setText = (elementId, text) => {
  const el = getElementById(elementId);
  if (el) {
    if (['TEXTAREA', 'INPUT'].includes(el.tagName)) {
      el.value = text;
    } else {
      el.innerText = text;
    }
  }
};

const swapImage = (imageId, newUrl) => {
  const el = getElementById(imageId);
  if (el) el.src = newUrl;
};

const enableButton = (buttonId, inputId, minLength) => {
  const input = getElementById(inputId);
  if (!input) return;
  input.addEventListener('input', () => {
    enable(buttonId, input.value && input.value.length >= minLength);
  });
};

const onClick = (elementId, handler) => {
  const el = getElementById(elementId);
  if (el) el.addEventListener('click', handler);
};

const onSubmit = (elementId, handler) => {
  const el = getElementById(elementId);
  if (el) el.addEventListener('submit', handler);
};

const focus = (elementId) => {
  const el = getElementById(elementId);
  if (el) {
    setTimeout(() => {
      el.focus();
    }, 0);
  }
};

const createTable = (id, contactAttr) => {
  try {
    const tbody = getElementById(id).getElementsByTagName('tbody')[0];
    Object.values(contactAttr)
      .filter(el => el.name.startsWith(appSettings.contactAttributesPrefix))
      .forEach(el => {
        const key = el.name.replace(appSettings.contactAttributesPrefix, '');
        const value = el.value;
        const tr = tbody.insertRow();
        const td1 = tr.insertCell();
        const td2 = tr.insertCell();
        td1.innerHTML = key;
        td2.innerHTML = value;
      });
  } catch (e) {
    console.log(e);
  }
};

const clearTable = (id) => {
  const tableBody = getElementById(id).getElementsByTagName('tbody')[0];
  tableBody.innerHTML = '';
};

const clearValue = (elementId) => {
  const el = getElementById(elementId);
  if (el) el.value = '';
};

const addListItem = (id) => {
  let listItems = '';
  appSettings.quickConnects.forEach(item => {
    listItems += `<option value="${item.name}"/>`;
  });
  document.getElementById(id).innerHTML = listItems;
};

const ui = {
  show,
  hide,
  enable,
  observe,
  getText,
  setText,
  swapImage,
  enableButton,
  onClick,
  onSubmit,
  focus,
  createTable,
  clearTable,
  clearValue,
  addListItem
};

export default ui;