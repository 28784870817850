import { ApiCore } from '../api/utilities/core';

const url = 'dispositions';
const token = ''; // Initialize token properly

const apiDisposition = new ApiCore({
  get: true,
  put: true,
  url: url,
  token: token,
});

export { apiDisposition };