import { ApiCore } from '../api/utilities/core';

const url = 'queuemetrics';
let token = ''; // Initialize token properly if needed

const apiQueueMetrics = new ApiCore({
  get: true,
  put: true,
  url: url,
  token: token,
});

export { apiQueueMetrics };