import React, { memo, useState, useEffect, useContext, useCallback } from "react";
import { AgentContext } from './AgentContextProvider';
import '../css/queue.css';
import MainTable from "./common/MainTable";
import { apiQueueMetrics } from '../services/models/queuemetrics.js';

const Queue = (props) => {
  const [queuemetrics, setQueueMetrics] = useState(null);
  const [queueColumns, setQueueColumns] = useState([]);
  const [queueRows, setQueueRows] = useState([]);
  const state = useContext(AgentContext);
  const queues = state.queues;
  const isLoggedIn = state.isLoggedIn;

  const formatTime = (val) => {
    if (val[0] === 'SLA') {
      return val[1] === 0 ? 'null' : `${val[1]}%`;
    } else if (val[0] === 'Oldest Contact Age' || val[0] === 'Answer Time(AVG)') {
      const s = val[1];
      return `${Math.floor(s / 60)}:${s % 60 < 10 ? '0' : ''}${s % 60}`;
    } else {
      return JSON.parse(val[1]);
    }
  };

  const setRows = useCallback((items) => {
    return items.map(item => {
      const row = {
        Queue: queues.filter(queue => queue.queueARN.includes(item.Id)).map(queue => queue.name)
      };
      item.Metrics.forEach(metric => {
        const [key, value] = Object.entries(metric)[0];
        row[key] = formatTime([key, value]);
      });
      return row;
    });
  }, [queues]);

  const setColumns = useCallback((items) => {
    const headers = items[0].Metrics.flatMap(Object.keys);
    return [{ id: 'Queue', label: 'Queue', minWidth: 170 }, ...headers.map(header => ({ id: header, label: header }))];
  }, []);

  const getIdFromArn = (arn) => {
    const queuePattern = /arn:aws:connect:[\w-]+:\w+:instance\/[\w-]+\/queue\/([\w-]+)/;
    const match = queuePattern.exec(arn);
    return match ? match[1] : null;
  };

  const getMetrics = useCallback(async () => {
    try {
      const queuearns = queues.map(queue => getIdFromArn(queue.queueARN));
      const res = await apiQueueMetrics.put({ queues: queuearns });
      const items = res.map(i => i);
      const obj = { val: items, timestamp: new Date().getTime() };
      localStorage.setItem("QueueMetrics", JSON.stringify(obj));
      setQueueMetrics(items);
      setQueueColumns(setColumns(items));
      setQueueRows(setRows(items));
    } catch (error) {
      console.error("Failed to fetch metrics:", error);
    }
  }, [queues, setColumns, setRows]);

  useEffect(() => {
    if (!isLoggedIn) return;
    const intervalId = setInterval(() => {
      const storedMetrics = JSON.parse(localStorage.getItem('QueueMetrics'));
      const storagetime = storedMetrics?.timestamp;
      const timenow = new Date().getTime();
      const passedtime = timenow - storagetime;

      if (!storagetime || passedtime > 5000) {
        getMetrics();
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [isLoggedIn, getMetrics]);

  useEffect(() => {
    if (!isLoggedIn) return;
    const timenow = new Date().getTime();
    const storedMetrics = JSON.parse(localStorage.getItem('QueueMetrics'));

    if (!storedMetrics || storedMetrics.val === '[]' || (timenow - storedMetrics.timestamp) > 5000) {
      getMetrics();
    } else {
      setQueueMetrics(storedMetrics.val);
    }
  }, [isLoggedIn, getMetrics]);

  return (
    <>
      {queuemetrics ? <MainTable columns={queueColumns} rows={queueRows} /> : <div>Loading...</div>}
    </>
  );
};

export default memo(Queue);