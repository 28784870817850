import React, { memo, useState, useContext } from "react";
import "amazon-connect-streams";
import IconButton from "@mui/material/IconButton";
import './queuetransfer.css';
import TransferCallIcon from '@mui/icons-material/PhoneForwardedSharp';
import { AgentContext } from '../AgentContextProvider';
import { genLogger } from "../../lib";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

const QueueTransfer = (props) => {
  const transferClient = props.transferClient;
  const connect = window.connect;
  const state = useContext(AgentContext);
  const idToken = state.idToken;
  const currentAgent = new connect.Agent();
  const queueRows = props.queueRows;
  const [sortModel, setSortModel] = useState([{ field: 'objectName', sort: 'asc' }]);

  const columns = [
    { field: 'objectName', headerName: 'Queue', editable: false, flex: 1 },
    {
      field: 'action',
      headerName: 'Transfer Call',
      flex: 1,
      renderCell: (params) => (
        <IconButton aria-label="transfer call" color="primary" onClick={() => handleTransferClick(params.row.id)}>
          <TransferCallIcon />
        </IconButton>
      )
    }
  ];

  const handleTransferClick = (id) => {
    const logger = genLogger("handleQueueTransferClick");
    try {
      const currentContactId = currentAgent.getContacts(connect.ContactType.VOICE)[0].contactId;
      const currentTimestamp = Math.round(Date.now() / 1000 + 259200);
      const contact = { contactId: currentContactId, xferQueueArn: id, transExpiryTTL: currentTimestamp };

      transferClient.putQueueTransfer(contact, idToken)
        .then(data => console.log("Successfully wrote queue transfer data", data))
        .catch(error => console.error("Error writing queue transfer data", error));

      const dummyTransferArn = process.env.REACT_APP_DUMMY_QUEUE_TRANSFER_ENDPOINT_ARN;
      const endpoint = new connect.Endpoint();
      endpoint.endpointARN = dummyTransferArn;
      endpoint.endpointId = dummyTransferArn;
      endpoint.type = connect.EndpointType.QUEUE;
      endpoint.name = process.env.REACT_APP_DUMMY_QUEUE_QUICK_CONNECT_NAME;

      currentAgent.getContacts(connect.ContactType.VOICE)[0].addConnection(endpoint, {
        success: (data) => logger.info("Queue transfer initiated successfully: ", data),
        failure: (data) => {
          logger.error("Error initiating queue transfer: ", data);
          window.alert('Unable to initiate call transfer at this time.');
        }
      });
    } catch (error) {
      logger.error("Error in handleQueueTransferClick", error);
    }
  };

  return (
    <div>
      <Box sx={{ height: '600px', width: 1 }}>
        <DataGrid
          columns={columns}
          rows={queueRows}
          slots={{ toolbar: GridToolbar }}
          disableColumnSelector
          autoPageSize
          slotProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
            },
          }}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
        />
      </Box>
    </div>
  );
};

export default memo(QueueTransfer);