import React, { memo, useCallback } from "react";
import TextField from "@mui/material/TextField";
import { useConnected, useDestroy } from '../../hooks.js';
import ui from '../ui.js';

const ContactAttributes = () => {
  const attributefields = window.attributes;

  // Hook for behavior when Contact is connected
  const onConnected = useCallback((c) => {
    try {
      const attr = c.getAttributes();
      if (attr === null) return;

      // If contact is a phone call
      const findqueue = attributefields.find(o => o.label === 'Queue');
      if (findqueue) {
        ui.setText("contact_queueArn", c.getQueue().name);
      }

      attributefields.forEach(field => {
        if (field.label !== 'Queue') {
          const value = attr[field.name]?.value || '';
          ui.setText(field.id, value);
        }
      });
    } catch (e) {
      console.error("Error in onConnected:", e);
    }
  }, [attributefields]);
  useConnected(onConnected);

  const onDestroy = useCallback(() => {
    const findqueue = attributefields.find(o => o.label === 'Queue');
    if (findqueue) {
      ui.setText("contact_queueArn", '');
    }
    attributefields.forEach(field => {
      if (field.label !== 'Queue') {
        ui.setText(field.id, '');
      }
    });
  }, [attributefields]);
  useDestroy(onDestroy);

  const setFields = (attrFields) => {
    return attrFields.map((attr) => {
      if (attr.label !== 'Queue') {
        return (
          <TextField
            key={attr.id}
            style={{ margin: '3px' }}
            select={attr.type === 'dropdown'}
            className='not_draggable'
            id={attr.id}
            label={attr.label}
            fullWidth
            disabled={attr.type !== 'dropdown'}
            InputLabelProps={{ shrink: true }}
          />
        );
      }
      return null;
    }).filter(Boolean);
  };

  return (
    <div className='attributesContainer'>
      <div className='attribute'>
        {/* Create Queue Field to ensure it's on top and full width */}
        <TextField
          style={{ margin: '3px' }}
          key='contact_queueArn'
          className='not_draggable'
          id='contact_queueArn'
          label='Queue'
          disabled={true}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        {setFields(attributefields)}
      </div>
    </div>
  );
};

export default memo(ContactAttributes);