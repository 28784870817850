import React, { useContext, useState } from "react";
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import IconButton from "@mui/material/IconButton";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ChatSharpIcon from '@mui/icons-material/ChatSharp';
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { AgentContext } from '../AgentContextProvider';
import './agentlist.css';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    margin: '5px',
    backgroundColor: 'red',
    fontWeight: 'bold',
    borderRadius: '5px'
  },
  body: {
    padding: 0,
    height: '80%',
    width: '80%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  },
  modalHeader: {
    backgroundColor: '#4CC6FF',
    color: 'black',
    borderRadius: '5px'
  }
};

const AgentList = ({ agentRows }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { routingProfile } = useContext(AgentContext);

  const agentRowsFiltered = agentRows
    .filter(agentRow => agentRow.summaryStatusName !== 'Offline')
    .filter(agentRow => agentRow.routingProfile === routingProfile);

  const columns = [
    { field: 'lastName', headerName: 'Last Name', editable: false, flex: 1 },
    { field: 'firstName', headerName: 'First Name', editable: false, flex: 1 },
    { field: 'username', headerName: 'Username', editable: false, flex: 1 },
    {
      field: 'summaryStatusName',
      headerName: 'Current Status',
      flex: 1,
      renderCell: params => {
        if (params.row.channel === 'CHAT') {
          return <ChatSharpIcon />;
        }
        if (params.row.channel === 'VOICE') {
          return <LocalPhoneSharpIcon />;
        }
        return <div>{params.row.summaryStatusName}</div>;
      }
    }
  ];

  return (
    <div>
      <IconButton aria-label="open" onClick={handleOpen}>
        <PeopleAltIcon />
      </IconButton>

      <Modal
        style={styles}
        open={open}
        onClose={handleClose}
      >
        <Paper style={styles.body}>
          <AppBar style={styles.modalHeader} position="static">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Agent Status
              </Typography>
              <div className='closeIcon'>
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <Box sx={{ height: 500, width: 1 }}>
            <DataGrid
              columns={columns}
              rows={agentRowsFiltered}
              slots={{ toolbar: GridToolbar }}
              disableColumnSelector
              autoPageSize
              slotProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                },
              }}
            />
          </Box>
        </Paper>
      </Modal>
    </div>
  );
};

AgentList.propTypes = {
  agentRows: PropTypes.arrayOf(PropTypes.shape({
    lastName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    summaryStatusName: PropTypes.string.isRequired,
    routingProfile: PropTypes.string.isRequired,
    channel: PropTypes.string
  })).isRequired
};

export default AgentList;