// core.js

import { apiProvider } from './provider';
import { authProvider } from './provider';

export class ApiCore {
  constructor(options) {
    const { url, get, put, post, patch, authpost } = options;

    if (get) {
      this.get = (path, model, headers) => {
        return apiProvider.get(`${url}/${path}`, model, headers);
      };
    }

    if (put) {
      this.put = (model, path, headers) => {
        return apiProvider.put(url, model, headers);
      };
    }

    if (post) {
      this.post = (model, headers) => {
        return apiProvider.post(url, model, headers);
      };
    }

    if (patch) {
      this.patch = (model, headers) => {
        return apiProvider.patch(url, model, headers);
      };
    }

    if (authpost) {
      this.authpost = (model) => {
        return authProvider.authpost(url, model);
      };
    }
  }
}