import { ApiCore } from '../api/utilities/core';

const url = 'oauth2/token';

const auth = new ApiCore({
  authpost: true,
  authget: true,
  url: url,
});

export { auth };