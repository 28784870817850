import React, { useContext, useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from '@mui/icons-material/Logout';
import session from './session.js';
import ContactList from './ContactList/ContactList';
import AgentList from './AgentList/AgentList';
import { AgentContext } from './AgentContextProvider';
import logo from '../images/optum-logo-ora-rgb.png';
import 'amazon-connect-streams';
import { connectName, instanceId } from '../config.js';
import AgentTransferModal from "./AgentTransfer/AgentTransferModal";
import AgentNotes from "./AgentNotes/AgentNotes.jsx";
import useWebSocket from "react-use-websocket";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: "99%",
    display: "flex"
  },
  logo: {
    margin: "auto"
  }
}));

export default function TopBar({
  onLayoutSave,
  items,
  onRemoveItem,
  onAddItem,
  originalItems,
  idToken,
  taskClient,
  transferClient
}) {
  const classes = useStyles();
  const state = useContext(AgentContext);
  const isLoggedIn = state.isLoggedIn;
  const agentId = state.id;
  const connect = window.connect;
  const channels = state.channels;
  const [unreadEvents, setUnreadEvents] = useState(false);
  const [newAgentStatus, setNewAgentStatus] = useState(false);
  const [newQueues, setNewQueues] = useState(false);
  const [agentRows, setAgentRows] = useState([]);
  const [queueRows, setQueueRows] = useState([]);

  const logout = async () => {
    session.agent.setState(session.agent.getAgentStates().find((state) => state.name === 'Offline'), {
      success: () => {
        state.setIsLoggedIn(false, '', [], [], [], [], '');
        fetch(`https://${connectName}.my.connect.aws/connect/logout`, { credentials: 'include', mode: 'no-cors' })
          .then(() => {
            const eventBus = connect.core.getEventBus();
            eventBus.trigger(connect.EventType.TERMINATE);
          });
        connect.core.terminate();
      },
      failure: (err) => console.log(err)
    });
  };

  const reconnectEvent = () => {
    console.info('calling reconnectEvent');
    setUnreadEvents(true);
    setNewAgentStatus(true);
    setNewQueues(true);
  };

  const Callbacks = {
    taskListUpdate: () => setUnreadEvents(true),
    agentListRefresh: () => setNewAgentStatus(true),
    queueListRefresh: () => setNewQueues(true),
    close: (data) => data,
    error: (data) => data,
    open: () => reconnectEvent(),
    ping: (data) => data,
  };

  const OnEventMessage = (data) => {
    console.info('Got event message');
    console.info(data);
    try {
      const event = JSON.parse(data.data);
      const eventType = event.event_type;
      return Callbacks[eventType](event);
    } catch {
      return data;
    }
  };

  const queryParams = { agentId, instanceId };

  useWebSocket(process.env.REACT_APP_WSS_API_BASE, {
    onMessage: (e) => OnEventMessage(e),
    onOpen: () => console.info('Connected'),
    onClose: () => console.info('Disconnected'),
    onError: (e) => console.info(e),
    shouldReconnect: () => true,
    queryParams
  });

  const updateAgentRows = useCallback(() => {
    console.info('updating agent rows');
    transferClient.getAgents(idToken)
      .then((response) => response.json())
      .then((res) => setAgentRows(res));
  }, [idToken, transferClient]);

  const updateQueueRows = useCallback(() => {
    transferClient.getQueues(idToken)
      .then((response) => response.json())
      .then((res) => setQueueRows(res));
  }, [idToken, transferClient]);

  useEffect(() => {
    console.info('Init only useEffect');
    updateAgentRows();
    updateQueueRows();
  }, [updateAgentRows, updateQueueRows]);

  useEffect(() => {
    if (newAgentStatus) {
      console.info('useEffect newAgentStatus updated : ' + newAgentStatus);
      updateAgentRows();
      setNewAgentStatus(false);
    }
  }, [newAgentStatus, updateAgentRows]);

  useEffect(() => {
    if (newQueues) {
      updateQueueRows();
      setNewQueues(false);
    }
  }, [newQueues, updateQueueRows]);

  return (
    <Card className={classes.root}>
      <div className={classes.logo}><img src={logo} width={140} height={40} alt='logo' /></div>
      {isLoggedIn && (
        <>
          <AgentNotes
            taskClient={taskClient}
            transferClient={transferClient}
            setUnreadEvents={setUnreadEvents}
            unreadEvents={unreadEvents}
            agentRows={agentRows}
          />
          {channels.includes('VOICE') && (
            <AgentTransferModal
              transferClient={transferClient}
              idToken={idToken}
              setNewAgentStatus={setNewAgentStatus}
              setNewQueues={setNewQueues}
              newAgentStatus={newAgentStatus}
              newQueues={newQueues}
              agentRows={agentRows}
              queueRows={queueRows}
            />
          )}
          <AgentList agentRows={agentRows} />
          <ContactList />
          <IconButton aria-label="save" onClick={logout}>
            <LogoutIcon />
          </IconButton>
        </>
      )}
    </Card>
  );
}