import React, { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "amazon-connect-streams";
import '../css/styles.css';

// Widgets
import Widget from './Widget';
import CustomCCP from "./CustomCCP";
import { AgentContext } from './AgentContextProvider';
import TopBar from './TopBar';
import { widgetmap } from '../widgets.js';
import { TaskClient } from '../services/clients/TaskClient';
import { TransferClient } from '../services/clients/TransferClient';

const componentList = {
  a: CustomCCP
};

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = ({ size, idToken, groups }) => {
  const taskClient = new TaskClient();
  const transferClient = new TransferClient();
  const state = useContext(AgentContext);
  const isLoggedIn = state.isLoggedIn;
  const widgets = state.widgets;

  const mapwidgets = useCallback((widgetname) => widgetmap[widgetname], []);

  const originalItems = useMemo(() => [
    { w: 3, h: 30, x: 0, y: 0, i: "a", static: false }
  ], []);

  const [items, setItems] = useState(originalItems);

  const onRemoveItem = (item) => {
    setItems(items.filter((i) => i.i !== item));
  };

  const onAddItem = (item) => {
    const newItem = originalItems.find((i) => i.i === item);
    if (newItem) {
      setItems([...items, newItem]);
    }
  };

  const getLayout = (key) => items.find((i) => i.i === key);

  useEffect(() => {
    widgets.forEach(widget => {
      componentList[widget.i] = mapwidgets(widget.name);
      originalItems.push({
        w: parseInt(widget.w),
        h: parseInt(widget.h),
        x: parseInt(widget.x),
        y: parseInt(widget.y),
        i: widget.i,
        static: JSON.parse(widget.static)
      });
    });
  
    setItems(originalItems);
  }, [isLoggedIn, widgets, originalItems, mapwidgets]);

  return (
    <>
      {isLoggedIn && (
        <TopBar
          items={items}
          onRemoveItem={onRemoveItem}
          onAddItem={onAddItem}
          isLoggedIn={isLoggedIn}
          originalItems={originalItems}
          idToken={idToken}
          taskClient={taskClient}
          transferClient={transferClient}
        />
      )}

      <ResponsiveGridLayout
        className="layout"
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 13, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={10}
        draggableCancel='.not_draggable'
        isBounded={true}
      >
        {items.map(key => (
          <div
            className='widget'
            key={key.i}
            state={state}
            data-grid={getLayout(key.i)}
          >
            <Widget
              style={{ border: 1 }}
              id={key.i}
              onRemoveItem={onRemoveItem}
              component={componentList[key.i]}
              idToken={idToken}
              groups={groups}
            />
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
};

export default Dashboard;