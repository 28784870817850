import React, { memo } from "react";

const Lex = () => {
  const response = {
    message: 'Sample Lex message goes here'
  };

  return (
    <>
      {response.message}
    </>
  );
};

export default memo(Lex);