import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';

const useObserver = ({ callback, element }) => {
  const observer = useRef(null);

  useEffect(() => {
    const current = element?.current;

    if (observer.current && current) {
      observer.current.unobserve(current);
    }

    observer.current = new ResizeObserver(callback);

    if (current) {
      observer.current.observe(current);
    }

    return () => {
      if (observer.current && current) {
        observer.current.unobserve(current);
      }
    };
  }, [element, callback]);

};

useObserver.propTypes = {
  element: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  callback: PropTypes.func.isRequired,
};

export default useObserver;