import React from 'react';
import './login.css';
import logo from '../../images/optum-logo-ora-rgb.png';

export default function Login() {
  return (
    <>
      <div className='logo-container'>
        <img src={logo} width={150} height={100} alt='Optum Logo' />
      </div>
      <h1 className="loading-login-page">
        <span className="let1">l</span>
        <span className="let2">o</span>
        <span className="let3">a</span>
        <span className="let4">d</span>
        <span className="let5">i</span>
        <span className="let6">n</span>
        <span className="let7">g</span>
      </h1>
    </>
  );
}