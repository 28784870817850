import React, { useState, useCallback } from 'react';

export const AgentContext = React.createContext({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  username: '',
  id: '',
  queues: [],
  channels: [],
  attributes: [],
  widgets: [],
  routingProfile: '',
  idToken: ''
});

export const AgentContextProvider = (props) => {
  const [state, setState] = useState({
    isLoggedIn: false,
    setIsLoggedIn: () => {},
    username: '',
    id: '',
    queues: [],
    channels: [],
    attributes: [],
    widgets: [],
    routingProfile: '',
    idToken: ''
  });

  const setIsLoggedIn = useCallback((isLoggedIn, username, id, queues, channels, attributes, widgets, routingProfile, idToken) => {
    setState((prevState) => ({
      ...prevState,
      isLoggedIn,
      username,
      id,
      queues,
      channels,
      attributes,
      widgets,
      routingProfile,
      idToken
    }));
  }, []);

  return (
    <AgentContext.Provider value={{ ...state, setIsLoggedIn }}>
      {props.children}
    </AgentContext.Provider>
  );
};