import React, { memo, useState, useEffect, useContext, useCallback } from "react";
import "amazon-connect-streams";
import session from './session.js';
import '../css/styles-agentboard.css';
import { AgentContext } from './AgentContextProvider';
import { ContactContext } from './ContactContextProvider';
import { apiUrl } from '../config.js';
import { useConnected, useDestroy, useCallCompleted } from '../hooks.js';

import PhoneIcon from '@mui/icons-material/Phone';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

const AgentBoard = () => {
  const [displayStatus, setDisplayStatus] = useState(null);

  const state = useContext(AgentContext);
  const contactstate = useContext(ContactContext);
  const isLoggedIn = state.isLoggedIn;
  const routingProfile = state.routingProfile;
  const username = state.username;
  const agent = session.agent;

  const setContact = useCallback((val, agentstatus, attributes = {}) => {
    contactstate.setOnContact(val, agentstatus, attributes);
  }, [contactstate]);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (contactstate.status === '') {
      contactstate.setOnContact(false, agent.getState().name);
    }
    const agentstatus = contactstate.status;
    const stateduration = agent.getStateDuration();

    const intervalId = setInterval(() => {
      const storagetime = JSON.parse(localStorage.getItem('AgentStatus')).timestamp;
      const timenow = new Date().getTime();
      const passedtime = timenow - storagetime;
      if (!storagetime || passedtime > 20000) {
        updateAgentStatus(routingProfile, username, agentstatus, stateduration);
      }
    }, 20000);

    return () => clearInterval(intervalId);
  }, [agent, contactstate, isLoggedIn, routingProfile, username]);

  useEffect(() => {
    if (contactstate.status === '') {
      contactstate.setOnContact(false, agent.getState().name);
    }
    const agentstatus = contactstate.status;
    const stateduration = agent.getStateDuration();
    const timenow = new Date().getTime();

    if (!localStorage.getItem('AgentStatus') || localStorage.getItem('AgentStatus').value === '[]') {
      updateAgentStatus(routingProfile, username, agentstatus, stateduration);
    } else if (timenow - JSON.parse(localStorage.getItem('AgentStatus')).timestamp > 20000) {
      updateAgentStatus(routingProfile, username, agentstatus, stateduration);
    }

    agent.onStateChange((agentstatechange) => {
      const agentstatus = agentstatechange.newState;
      const contactlist = agent.getContacts();

      if (Array.isArray(contactlist) && contactlist.length === 0) {
        setContact(false, agentstatus);
      }
    });
  }, [agent, contactstate, routingProfile, setContact, username]);

  const onConnected = useCallback((c) => {
    const attr = c.getAttributes();
    const agentstatus = c.getType();
    setContact(true, agentstatus, attr);
  }, [setContact]);
  useConnected(onConnected);

  const onCallCompleted = useCallback((c) => {
    const agentstatus = agent.getState().name;
    setContact(true, agentstatus);
  }, [agent, setContact]);
  useCallCompleted(onCallCompleted);

  const onDestroy = useCallback((c) => {
    const agentstatus = agent.getState().name;
    setContact(false, agentstatus);
  }, [agent, setContact]);
  useDestroy(onDestroy);

  async function updateAgentStatus(routingProfile, username, agentstate, stateduration) {
    const response = await fetch(apiUrl + '/agentstatus', {
      method: 'PUT',
      body: JSON.stringify({ agent: username, routingProfile, state: agentstate, duration: stateduration })
    });

    const result = await response.json();
    const items = result.map((i) => i);

    const obj = { val: items, timestamp: new Date().getTime() };
    localStorage.setItem("AgentStatus", JSON.stringify(obj));

    setDisplayStatus(JSON.parse(localStorage.getItem('AgentStatus')).val);
  }

  return (
    <>
      {displayStatus ? (
        <table className='not_draggable'>
          <tbody id="content" className='table'>
            {displayStatus.map((i) => (
              <tr key={i.Username}>
                <td style={{ textAlign: 'left' }}>{i.Username}</td>
                <td>
                  {(() => {
                    switch (i.AgentStatus) {
                      case "voice": return <PhoneIcon />;
                      case "chat": return <ChatBubbleIcon />;
                      default: return i.AgentStatus;
                    }
                  })()}
                </td>
                <td>{i.Duration}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default memo(AgentBoard);