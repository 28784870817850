import { instanceId } from "../../config";

export class TransferClient {
  constructor() {
    if (TransferClient._instance) {
      throw new Error("Singleton classes can't be instantiated more than once.");
    }
    TransferClient._instance = this;
  }

  // Agent Transfers
  async getAgents(token) {
    const uri = `${process.env.REACT_APP_TRANSFER_URI}/agents/${instanceId}`;
    const request = {
      method: 'GET',
      headers: { 'Accept': '*/*', 'Authorization': `Bearer ${token}` },
    };

    const response = await fetch(uri, request);

    if (!response.ok) {
      console.error('Error fetching agents:', response);
      throw new Error('Failed to fetch agents');
    }
    return response;
  }

  async putAgentTransfer(contact, token) {
    const uri = `${process.env.REACT_APP_TRANSFER_URI}/agenttransfer/${instanceId}`;
    const request = {
      method: 'PUT',
      headers: { 'Accept': '*/*', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(contact),
    };

    const response = await fetch(uri, request);

    if (!response.ok) {
      console.error('Error creating agent transfer:', response);
      throw new Error('Failed to create agent transfer');
    }
    return response;
  }

  // Queue Transfers
  async getQueues(token) {
    const uri = `${process.env.REACT_APP_TRANSFER_URI}/queues/${instanceId}`;
    const request = {
      method: 'GET',
      headers: { 'Accept': '*/*', 'Authorization': `Bearer ${token}` },
    };

    const response = await fetch(uri, request);

    if (!response.ok) {
      console.error('Error fetching queues:', response);
      throw new Error('Failed to fetch queues');
    }
    return response;
  }

  async putQueueTransfer(contact, token) {
    const uri = `${process.env.REACT_APP_TRANSFER_URI}/queuetransfer/${instanceId}`;
    const request = {
      method: 'PUT',
      headers: { 'Accept': '*/*', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(contact),
    };

    const response = await fetch(uri, request);

    if (!response.ok) {
      console.error('Error creating queue transfer:', response);
      throw new Error('Failed to create queue transfer');
    }
    return response;
  }
}

TransferClient._instance = null;