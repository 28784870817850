const session = {
    contact: {},
    contactId: {},
    agent: {},
    state: {
        incoming: false,
        accepted: false,
        connected: false,
        callEnded: false,
        chatEnded: false,
        ticketSubmitted: false
    },
    transcriptHtml: '',
    visitedTabs: {},

    clear: function () {
        this.contact = {};
        this.contactId = {};
        this.agent = {};
        this.state = {
            incoming: false,
            accepted: false,
            connected: false,
            callEnded: false,
            chatEnded: false,
            ticketSubmitted: false
        };
        this.transcriptHtml = '';
        this.visitedTabs = {};
        this.user = null;
        this.dialOut = null;
    }
};

export default session;