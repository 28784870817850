import "amazon-connect-streams";
import "amazon-connect-chatjs";
import "amazon-connect-taskjs";
import React, { memo, useRef, useEffect, useContext } from "react";
import { AgentContext } from './AgentContextProvider';
import { genLogger } from "../lib.js";
import subscribeToAgentEvents from './agentEvents.js';
import { loginUrl, ccpUrl } from '../config.js';
import { apiCCPConfig } from '../services/models/ccpconfig.js';
import Icon from "./assets/optum-icon.png";

const connect = window.connect;
const name = "CustomCCP";
const { log } = genLogger(name);

const softphoneManagerParams = {
  allowFramedSoftphone: true,
  VDIPlatform: "CITRIX"
};

export let idToken;

const notifyMe = () => {
  if (Notification.permission !== 'granted') {
    Notification.requestPermission();
  } else {
    const notification = new Notification('Amazon Connect Chat', {
      icon: Icon,
      body: 'You have a new chat message',
    });
    notification.onclick = () => window.focus();
  }
};

const getNumberOfDays = () => {
  let contactHistory = JSON.parse(localStorage.getItem('ContactHistory')) || [];
  if (contactHistory.length !== 0) {
    const today = new Date();
    const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    contactHistory = contactHistory.filter(contact => contact.date === date);
    localStorage.setItem("ContactHistory", JSON.stringify(contactHistory));
  }
};

const getCcpConfig = async (routingProfile) => {
  try {
    const response = await apiCCPConfig.put({ routingProfile });
    return response;
  } catch (e) {
    console.error(e);
    return e;
  }
};

const getAgentId = async (agentQueue) => {
  try {
    const AGENT_QUEUE_PATTERN = /arn:aws:connect:[\w|-]+:\w+:instance\/[\w|-]+\/queue\/agent\/([\w|-]+)/;
    const groups = AGENT_QUEUE_PATTERN.exec(agentQueue[0].queueARN);
    return groups[1];
  } catch (e) {
    console.error(e);
  }
};

const initializeCCP = (ref, softphoneManagerParams, setIsLoggedIn, token) => {
  const initCCPParam = {
    ccpUrl: ccpUrl,
    loginPopup: true,
    loginPopupAutoClose: true,
    loginUrl: loginUrl,
    region: "us-east-1",
    pageOptions: {
      enableAudioDeviceSettings: true,
      enablePhoneTypeSettings: true,
    },
    softphone: {
      allowFramedSoftphone: true,
    },
  };

  if (softphoneManagerParams.VDIPlatform) {
    initCCPParam.softphone.VDIPlatform = "CITRIX";
  }

  connect.core.initCCP(ref.current, initCCPParam);

  connect.contact((contact) => {
    if (contact.getType() !== connect.ContactType.CHAT) return;
    contact.onConnecting(() => console.log("incoming"));
    contact.onAccepted(async () => {
      if (contact.getAgentConnection().getMediaType() === connect.MediaType.CHAT) {
        const controller = await contact.getAgentConnection().getMediaController();
        controller.onMessage((response) => {
          if (response.data.ParticipantRole === 'CUSTOMER') {
            notifyMe();
          }
        });
      }
    });
  });

  connect.agent(subscribeToAgentEvents);

  const eventBus = connect.core.getEventBus();
  eventBus.subscribe(connect.AgentEvents.INIT, async (agent) => {
    try {
      const agentQueue = agent.getConfiguration().routingProfile.queues.filter(queue => queue.name === null);
      const username = agent.getConfiguration().username;
      const agentId = await getAgentId(agentQueue);
      const routingProfile = agent.getRoutingProfile();
      const queues = routingProfile.queues.filter(items => items.name !== null);
      const rawChannels = agent.getChannelConcurrency();
      const channels = Object.entries(rawChannels).filter(([key, value]) => value > 0).map(([k]) => k);
      const ccpConfig = await getCcpConfig(routingProfile.name);
      const widgets = ccpConfig.ccpWidgets;
      window.attributes = ccpConfig.ccpAttributes;
      getNumberOfDays();
      setIsLoggedIn(true, username, agentId, queues, channels, window.attributes, widgets, routingProfile.name, token.current);
    } catch (e) {
      console.error(e);
    }
  });
};

const checkVdiClientStatus = (initializeCCP, ref, softphoneManagerParams, setIsLoggedIn, token) => {
  console.log("***********Checking VDI Status**************");
  let statusMessage = "VDI client connecting ...";
  let allowFallback = true;
  let isTimerExpired = false;

  const timer = setTimeout(() => {
    isTimerExpired = true;
    statusMessage = "VDI client disconnected. Disconnect reason: Failed to get a response from Citrix UC SDK";
    if (allowFallback) {
      delete softphoneManagerParams.VDIPlatform;
      statusMessage += " - Fallback to non-redirected audio enabled";
    }
    console.log(statusMessage);
    initializeCCP(ref, softphoneManagerParams, setIsLoggedIn, token);
  }, 5000);

  new connect.CitrixVDIStrategy();

  if (window.CitrixWebRTC && typeof window.CitrixWebRTC.setVMEventCallback === 'function') {
    window.CitrixWebRTC.setVMEventCallback((event) => {
      if (['vdiClientConnected', 'vdiClientDisconnected'].includes(event.event) && !isTimerExpired) {
        clearTimeout(timer);
        if (event.event === 'vdiClientConnected') {
          const remoteAudioElement = document.getElementById('remote-audio');
          window.CitrixWebRTC.mapAudioElement(remoteAudioElement);
          statusMessage = "VDI client connected.";
        } else {
          statusMessage = `VDI client disconnected. Disconnect reason: ${event.reason}`;
          if (allowFallback) {
            delete softphoneManagerParams.VDIPlatform;
            statusMessage += " - Fallback to non-redirected audio enabled";
          }
        }
        console.log(statusMessage);
        isTimerExpired = true;
        initializeCCP(ref, softphoneManagerParams, setIsLoggedIn, token);
      }
    });
  } else {
    console.log('window.CitrixWebRTC.setVMEventCallback is not available.');
  }
};

const CustomCCP = ({ idToken: propIdToken, groups }) => {
  const ref = useRef();
  const { idToken: stateIdToken, setIsLoggedIn } = useContext(AgentContext);
  const token = useRef(null);
  idToken = stateIdToken;

  const styles = {
    ccp: {
      height: groups.includes('Salesforce') ? 0 : "500px",
      width: groups.includes('Salesforce') ? 0 : "98%",
    }
  };

  useEffect(() => {
    token.current = propIdToken;
  }, [propIdToken]);

  useEffect(() => {
    try {
      log("init start");
      if (typeof window === "undefined") throw new Error("Window missing");
      if (typeof window.connect === "undefined") throw new Error("Global Connect parameter missing");
      checkVdiClientStatus(initializeCCP, ref, softphoneManagerParams, setIsLoggedIn, token);
    } catch (e) {
      console.error(e);
    }
  }, [setIsLoggedIn]);

  return (
    <div ref={ref} style={styles.ccp}>
      <audio id="remote-audio" autoPlay></audio>
    </div>
  );
};

export default memo(CustomCCP);