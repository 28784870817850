import { AwsClient } from 'aws4fetch';

export const connectName = process.env.REACT_APP_CONNECT_NAME;
export const ccpUrl = process.env.REACT_APP_CCP_URL;
export const apiUrl = process.env.REACT_APP_URL;
export const instanceId = process.env.REACT_APP_INSTANCE_ID;
export const loginUrl = process.env.REACT_APP_LOGIN_URL;
export const clientId = process.env.REACT_APP_CLIENT_ID;
export const authUrl = process.env.REACT_APP_COGNITO_LOGIN_URL;
export const redirectUri = process.env.REACT_APP_REDIRECT_URI;
export const smsDomain = process.env.REACT_APP_SMS_DOMAIN;
export const signInUrl = `${authUrl}/login?client_id=${clientId}&response_type=code&scope=openid&redirect_uri=${redirectUri}`;
export const slaTime = 20;
export const tz = 'US/Central';

export let awsAccessKey = '';
export let awsSecretKey = '';
export let awsSessionToken = '';
export let apiReq = new AwsClient({
  accessKeyId: awsAccessKey,
  secretAccessKey: awsSecretKey,
  sessionToken: awsSessionToken,
  service: 'execute-api',
  region: 'us-east-1'
});