import axios from 'axios';
import { apiUrl, authUrl } from '../../../config.js';
import { handleResponse, handleError } from './response';

let accesstoken = '';

const put = (resource, model) => {
  return axios
    .put(`${apiUrl}/${resource}`, model, { headers: { Authorization: accesstoken } })
    .then(handleResponse)
    .catch(handleError);
};

const get = (resource, model) => {
  return axios
    .get(`${apiUrl}/${resource}`, { headers: { Authorization: accesstoken }, params: model })
    .then(handleResponse)
    .catch(handleError);
};

const post = (resource, model) => {
  return axios
    .post(`${apiUrl}/${resource}`, model, { headers: { Authorization: accesstoken } })
    .then(handleResponse)
    .catch(handleError);
};

const patch = (resource, model) => {
  return axios
    .patch(`${apiUrl}/${resource}`, model, { headers: { Authorization: accesstoken } })
    .then(handleResponse)
    .catch(handleError);
};

const authpost = (resource, model) => {
  return axios
    .post(`${authUrl}/${resource}`, {}, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, params: model })
    .then((res) => {
      accesstoken = res.data.id_token;
      return res;
    })
    .catch(handleError);
};

export const apiProvider = {
  get,
  put,
  post,
  patch,
};

export const authProvider = {
  authpost,
};