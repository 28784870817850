import session from './session.js';
import ui from './ui.js';

function handleAgentEvents(agent) {
  window.clearTimeout(window.connectTimeout);
  window.clearTimeout(window.vfConnectTimeout);

  ui.hide('loadingContainer');
  ui.hide('ccpContainer');
  ui.hide('callAttributesContainer');
  ui.show('ccpContainer');

  session.agent = agent;

  agent.onRefresh(handleAgentRefresh);
  agent.onRoutable(handleAgentRoutable);
  agent.onNotRoutable(handleAgentNotRoutable);
  agent.onOffline(handleAgentOffline);
  agent.onStateChange(handleAgentStateChange);

  const w = window.open('', window.connect.MasterTopics.LOGIN_POPUP);
  if (w) {
    w.close();
  }

  function handleAgentRefresh(agent) {
    session.agent = agent;
    window.agent = agent;
  }

  function handleAgentRoutable(agent) {
    // Handle agent routable state
  }

  function handleAgentNotRoutable(agent) {
    // Handle agent not routable state
  }

  function handleAgentOffline(agent) {
    // Handle agent offline state
  }

  function handleAgentStateChange(agent) {
    // Handle agent state change
  }
}

export default handleAgentEvents;