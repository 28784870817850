import React, { useContext } from 'react';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { ContactContext } from '../ContactContextProvider';
import Button from '@mui/material/Button';
import './calldisposition.css';

export default function DispositionModal(props) {
  const [setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  let contactstate = useContext(ContactContext);
  const {
    submitDisabled,
    updateDisposition,
    dispositions,
    handleChange,
    modalHeight,
    modalWidth,
    selectValues,
    openModal
  } = props;

  const style = {
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: '5px',
      margin: '5px',
      backgroundColor: 'red',
      fontWeight: 'bold',
      borderRadius: '5px'
    },
    body: {
      padding: 5,
      height: modalHeight,
      width: modalWidth,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4
    }
  };

  let primarycontactid = contactstate.primarycontactid;

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={updateDisposition}>
          <Paper style={style.body}>
            <div style={style.header}>Please submit disposition to continue</div>

            <>
              <TextField
                style={{ marginRight: '5px', marginBottom: '10px' }}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                className="not_draggable"
                value={primarycontactid}
                id="contactid"
                label="Current Contact"
                fullWidth
              />

              {Object.entries(dispositions).map(([key, value]) => (
                <div key={key + '-modal-div'}>
                  <TextField
                    key={key + '-modal-select'}
                    style={{ paddingBottom: '10px' }}
                    id={key}
                    select
                    required
                    label={key}
                    value={selectValues[key] || ''}
                    className="not_draggable"
                    onChange={(e) => handleChange(e, key)}
                    variant="outlined"
                    size="small"
                    fullWidth
                  >
                    {value.map((val) =>
                      Object.entries(val).map(([k, v]) => (
                        <MenuItem id={k} value={v} key={key + '-modal-value'}>
                          {v}
                        </MenuItem>
                      ))
                    )}
                  </TextField>
                </div>
              ))}

              <Button type="submit" variant="contained" disabled={submitDisabled}>
                Submit
              </Button>
            </>
          </Paper>
        </form>
      </Modal>
    </div>
  );
}
