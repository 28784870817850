import { instanceId } from '../../config';

export class TaskClient {
  constructor() {
    if (TaskClient._instance) {
      throw new Error("Singleton classes can't be instantiated more than once.");
    }
    TaskClient._instance = this;
  }

  async getTasks(agentId, token) {
    const uri = `${process.env.REACT_APP_TASKS_URI}/task/read/${instanceId}/${agentId}`;
    const request = {
      method: 'GET',
      headers: { 'Accept': '*/*', 'Authorization': `Bearer ${token}` },
    };
    const response = await fetch(uri, request);

    if (!response.ok) {
      console.error('Error fetching tasks:', response);
      throw new Error('Failed to fetch tasks');
    }
    return response;
  }

  async putTask(task, token) {
    const uri = `${process.env.REACT_APP_TASKS_URI}/task/create/${instanceId}`;
    const request = {
      method: 'PUT',
      headers: { 'Accept': '*/*', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(task),
    };
    const response = await fetch(uri, request);

    if (!response.ok) {
      console.error('Error creating task:', response);
      throw new Error('Failed to create task');
    }
    return response;
  }

  async updateTask(task, token) {
    const uri = `${process.env.REACT_APP_TASKS_URI}/task/update/${instanceId}`;
    const request = {
      method: 'PATCH',
      headers: { 'Accept': '*/*', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(task),
    };
    const response = await fetch(uri, request);

    if (!response.ok) {
      console.error('Error updating task:', response);
      throw new Error('Failed to update task');
    }
    return response;
  }
}

TaskClient._instance = null;