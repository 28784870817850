import session from './session.js';
import ui from './ui.js';
import { getSmsTabs, postSmsMessage } from './contactEvents.js';
import { apiSapId } from '../services/models/sapid.js';
import { instanceId, smsDomain } from '../config.js';

// Update SAP ID
export const updateSapId = () => {
  const currentContact = session.contact;

  const email = document.getElementById("contact_email").value;
  const sapId = document.getElementById("sap_id").value;
  const firstName = document.getElementById("contact_fname").value;
  const lastName = document.getElementById("contact_lname").value;

  if (JSON.stringify(currentContact) === '{}') {
    console.log("No active contact. Cannot update");
    return false;
  }

  if (!sapId || !email || !firstName || !lastName) {
    alert("Please ensure FirstName, LastName, Email and SAP ID are populated.");
    console.log("Check values - SAP ID not updated");
    resetFields();
    return false;
  }

  if (isNaN(Number(sapId)) || (sapId.length !== 7 && sapId.length !== 8)) {
    alert("SAP ID has to be 7 or 8 digit number. Retry.");
    resetFields();
    return false;
  }

  const reqBody = {
    sapId,
    Email: email,
    FirstName: firstName,
    LastName: lastName,
    instanceId,
    contactId: currentContact.getContactId(),
    operation: "update_sap_id"
  };

  console.log(reqBody);

  try {
    apiSapId.post(reqBody);
  } catch (err) {
    console.error('Attempted to update SAP ID', err);
  }
};

export const refreshSms = () => {
  const currentContact = session.contact;

  const email = document.getElementById("contact_email").value;
  const sapId = document.getElementById("sap_id").value;

  if (JSON.stringify(currentContact) === '{}') {
    console.log("No active contact. Cannot update");
    return false;
  }

  if (!sapId || !email || sapId === 'NOT_FOUND') {
    console.log("Check values - SAP ID not updated");
    return false;
  }

  try {
    postSmsMessage("updateSapId", currentContact);
  } catch (err) {
    console.error('Attempted to update SAP ID', err);
  }
};

export const updatePostMessage = (eventName, smsContact, reqBody) => {
  try {
    const attr = smsContact.getAttributes();
    const queueId = ((smsContact.getQueue().queueARN).split("/"))[3];
    const smsTabs = getSmsTabs();

    const contactMessage = {
      event: eventName,
      contactId: reqBody.contactId,
      firstName: reqBody.FirstName,
      lastName: reqBody.LastName,
      email: reqBody.Email,
      product: attr['Product'].value,
      category: attr['Category'].value,
      serviceNumber: attr['ServiceNumber'].value,
      subject: attr['Subject'].value,
      queueName: smsContact.getQueue().name,
      queueId,
      agent: session.agent.getConfiguration().username,
      sapId: reqBody.sapId
    };

    const message = JSON.stringify(contactMessage);
    console.log("PostMessage:", eventName, message);

    const index = smsTabs.find(element => element.contact_id === smsContact.contactId);
    const iframe = document.getElementById(index.iframeId).contentWindow;
    iframe.postMessage(message, smsDomain);
  } catch (err) {
    console.error('Failed to update post message', err);
  }
};

export const resetFields = () => {
  const currentContact = session.contact;
  const attr = currentContact.getAttributes();

  ui.setText("contact_fname", attr['FirstName'].value);
  ui.setText("contact_lname", attr['LastName'].value);
  ui.setText("contact_email", attr['Email'].value);
  ui.setText("sap_id", attr['sapId'].value);

  document.getElementById("sap_id").disabled = true;
  document.getElementById("contact_fname").disabled = true;
  document.getElementById("contact_lname").disabled = true;
  document.getElementById("contact_email").disabled = true;
  document.getElementById("cancelbtn").disabled = true;
  document.getElementById("editbtn").innerHTML = "Edit";
};