import React, { memo, useCallback, useState, useContext } from "react";
import "amazon-connect-streams";
import IconButton from "@mui/material/IconButton";
import TransferCallIcon from '@mui/icons-material/PhoneForwardedSharp';
import { AgentContext } from '../AgentContextProvider';
import { genLogger } from "../../lib";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

const style = {
  container: {
    height: '600px',
    width: '100%'
  }
};

const AgentTransfer = (props) => {
  const { handleClose, transferClient, agentRows } = props;
  const [sortModel, setSortModel] = useState([{ field: 'lastName', sort: 'asc' }]);

  const state = useContext(AgentContext);
  const idToken = state.idToken;
  const connect = window.connect;

  const columns = [
    { field: 'lastName', headerName: 'Last Name', editable: false, flex: 1 },
    { field: 'firstName', headerName: 'First Name', editable: false, flex: 1 },
    { field: 'username', headerName: 'Username', editable: false, flex: 1 },
    { field: 'summaryStatusName', headerName: 'Current Status', editable: false, flex: 1 },
    {
      field: 'action',
      headerName: 'Transfer Call',
      flex: 1,
      renderCell: (params) => (
        <IconButton aria-label="transfer call" color="primary" onClick={() => handleTransferClick(params.row.id)}>
          <TransferCallIcon />
        </IconButton>
      )
    }
  ];

  const handleTransferClick = useCallback((id) => {
    const logger = genLogger("handleTransferClick");
    const currentAgent = new connect.Agent();
    try {
      const contacts = currentAgent.getContacts(connect.ContactType.VOICE);
      if (contacts.length === 0) {
        throw new Error("No active voice contacts found.");
      }
  
      const currentContactId = contacts[0].contactId;
      const currentTimestamp = Math.round(Date.now() / 1000 + 259200);
      const contact = { contactId: currentContactId, xferAgentArn: id, transExpiryTTL: currentTimestamp };
  
      transferClient.putAgentTransfer(contact, idToken)
        .then(data => console.log("Successfully wrote agent transfer data", data))
        .catch(error => console.error("Error writing agent transfer data", error));
  
      const dummyTransferArn = process.env.REACT_APP_DUMMY_AGENT_TRANSFER_ENDPOINT_ARN;
      const endpoint = new connect.Endpoint({
        endpointARN: dummyTransferArn,
        endpointId: dummyTransferArn,
        type: connect.EndpointType.AGENT,
        name: "Agent Transfer"
      });
  
      contacts[0].addConnection(endpoint, {
        success: (data) => {
          logger.info("Agent transfer initiated successfully: ", data);
          handleClose();
        },
        failure: (data) => {
          logger.error("Error initiating agent transfer: ", data);
          window.alert('Unable to initiate call transfer at this time.');
        }
      });
    } catch (error) {
      logger.error("Error in handleTransferClick", error);
    }
  }, [idToken, transferClient, handleClose, connect.ContactType.VOICE, connect.Endpoint, connect.EndpointType.AGENT, connect.Agent]);

  return (
    <div>
      <Box sx={style.container}>
        <DataGrid
          columns={columns}
          rows={agentRows}
          slots={{ toolbar: GridToolbar }}
          disableColumnSelector
          autoPageSize
          slotProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
            },
          }}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
        />
      </Box>
    </div>
  );
};

export default memo(AgentTransfer);